<template>
  <div class="wrap">
   <Loading v-show="$store.state.isLoading"/>
   <van-list v-show="!$store.state.isLoading">
      <van-col span="22" offset="1" v-for="(item,id) in list" :key="id" style="margin-top:10px;border-radius:5px;">
        <!-- <span class="dot"></span> -->
        <!-- <van-cell is-link :center="true" 
          :to="{path: '/selfRepaymentDetail', 
                query: { contractNo: item.contract_number }
              }"
        > -->
        <van-cell v-if="item.con_type=='huizu'" :center="true" @click="nextTo(item)">
          <template #title>
            <span class="custom-title" style="font-size:12px;color:#969799;">{{item.contract_status}}</span>
          </template>
          <template #label>
            <span class="custom-title" style="font-size:14px;color:#323233;">{{item.contract_number}}</span>
            <span class="custom-title" style="font-size:12px;color:#969799;margin-top:6px">{{item.vin}}</span>
          </template>
        </van-cell>
      </van-col>
    </van-list>
  </div>
</template>
<script>
  import {getOpenId} from '@/server'
  import { Toast,Dialog } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
    name: 'applyQuery',
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      accessToken:this.$store.state.accessToken,
      finished: false,
      list:{},
    };
  },
  mounted(){
    let that = this;
    sessionStorage.setItem('key', 'contract2');
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    	if(sessionStorage.getItem('key') == 'contract2') {
        that.$router.push({ path: '/personalCenter' });
      }
    }, false);
  },
  created(){
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          // localStorage.setItem("openId", res.openid);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.accessToken = res.access_token;
          this.cardId = res.card_id;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/selfRepayment' });
          }else{
            this.list = this.$store.state.dataListTotal;
          }
        }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/selfRepayment');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            // localStorage.setItem("openId", res.openid);
            setCookie('openId',res.openid)
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.accessToken = res.access_token;
            this.cardId = res.card_id;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/selfRepayment' });
            }else{
              this.list = this.$store.state.dataListTotal;
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  methods: {
    nextTo(item){
      if(item.if_startday=='y'){
        Toast.fail({
          message: '当前无应还款金额，无需还款',
					duration:'3000'
        })
      }else{
        this.$store.commit(
        'settQueryContDetail', {
          contractNo:item.contract_number,
          conType:item.con_type
        }
      );
      this.$router.push({path: '/selfRepaymentDetail'});
      }
    }
  },
};
</script>
<style scoped>
  .wrap{
    /* padding: 20px 0px;  */
    overflow: hidden;
    min-height: 100vh !important;
    background: #F8F8F8;
  }
  .custom-title {
    display:block;
    /* vertical-align: middle; */
  }
  .custom-date {
    display:block;
    color: #999999;
    font-size: 12px;
  }
  .custom-status{
    font-size: 11px;
  }
  
</style>